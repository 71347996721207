import React, { useEffect, useRef, useState } from 'react';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Crisp } from 'crisp-sdk-web';

const customStyle = {
    visibility: {
        desktop: {
            xOffset: '0',
            yOffset: '60',
            position: 'br',
        },

        mobile: {
            xOffset: 0,
            yOffset: 60,
            position: 'br',
        },
    },
};

function Service() {
    const [isLoading, setIsLoading] = useState(true);

    const talkTo = useRef();

    // useEffect(() => {
    //     toast.success('Vui lòng đợi chút, trong thời gian chúng tôi kết nối với CSKH Target Corporation !', {
    //         theme: 'light',
    //     });

    //     setTimeout(() => {
    //         setIsLoading(false);
    //         talkTo.current.maximize();
    //     }, 5000);
    // }, []);

    // useEffect(() => {
    //     return () => {
    //         talkTo.current.hideWidget();
    //     };
    // }, []);

    const intervalRef = useRef(null);

    const handleLivechat = () => {
        // window.open('https://livechat123.vip/chat/43a0e7a2-0ec6-4c2c-925f-123f4281d27b');
        // Crisp.chat.open();
    };

    // useEffect(() => {
    //     window.open('https://livechat123.vip/chat/43a0e7a2-0ec6-4c2c-925f-123f4281d27b');
    // }, []);

    // useEffect(() => {
    //     Crisp.configure('b8b5785b-285b-4bc7-bdab-7841726a4777');
    //     intervalRef.current = setInterval(() => {
    //         const chat = document.querySelector('.crisp-client .cc-tlyw[data-full-view=true] .cc-kxkl .cc-nsge');
    //         if (chat) {
    //             chat.style.cssText = 'bottom: 60px !important';
    //             clearInterval(intervalRef.current);
    //         }
    //     }, 100);

    //     return () => {
    //         clearInterval(intervalRef.current);
    //     };
    // }, []);

    // useEffect(() => {
    //     return () => {
    //         console.log(talkTo.current);
    //     };
    // });

    // function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
    //     console.log('LiveChatWidget.onNewEvent', event);
    // }

    return (
        <>
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <ReactLoading type="bubbles" color="#ff8a00" height={10} width={70} />
                </div>
            )}

            <LiveChatWidget license="18430581" visibility="maximized" />

            {/* <TawkMessengerReact
                propertyId="65f9a076a0c6737bd122824c"
                widgetId="1hpbhlksk"
                ref={talkTo}
                customStyle={customStyle}
            /> */}
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default Service;
