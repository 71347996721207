import React from 'react';
import mail from '../../assets/images/mail.png';
import chuong from '../../assets/images/chuong.png';
import iconNext from '../../assets/images/icon-next.png';
import { useTranslation } from 'react-i18next';
function TinNhanHT() {
    const { t } = useTranslation();
    return (
        <div>
            <img src={mail} alt="" className="w-[390px] h-[260px]" />
            <div className="Notice_ul h-[200px] rounded-3xl flex flex-col">
                <div className="flex bg-[#fff] rounded-xl py-3 px-4 items-center justify-between mt-4 mx-4">
                    <img src={chuong} alt="" className="w-[20px] h-[20px]" />
                    <span className="text-xl">{t('content.tinNhanHeThong.tinNhanHeThong')}</span>
                    <img src={iconNext} alt="" className="w-[16px] h-[16px]" />
                </div>
                <div className="text-2xl text-justify pt-8 px-4">
                    <p>
                        Hệ thống đang áp dụng Chương trình "Nâng Cấp Gian Hàng - Nhận Ngàn Tri Ân" dành cho tất cả các
                        cấp bậc Thành viên đã và đang đồng hành cùng Target Corporation trong thời gian qua !
                    </p>
                    <p>Mức nâng cấp thành viên Vàng bạn nhận được 88$ tri ân từ hệ thống !</p>
                    <p>Mức nâng cấp thành viên Bạch Kim bạn nhận được 288$ tri ân từ hệ thống !</p>
                    <p>Mức nâng cấp thành viên Kim Cương bạn nhận được 588$ tri ân từ hệ thống !</p>
                </div>
                {/* <span className="text-2xl text-center pt-8">{t('content.tinNhanHeThong.kCoDuLieu')}</span> */}
            </div>
        </div>
    );
}

export default TinNhanHT;
